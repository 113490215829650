import $ from 'jquery'
import { selectors } from '../selectors'

const toggleKontomatikIncome = () => {
    const checkAll = $(selectors.buttons.toggleKontomatikIncomeAll).is(':checked')

    $(selectors.buttons.checkKontomatikIncomeSource).prop('checked', checkAll)
}

export const init = () => {
    $(selectors.buttons.toggleKontomatikIncomeAll).on('change', () => toggleKontomatikIncome())
}
